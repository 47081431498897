import React from "react";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import Img from "gatsby-image";
import Link from "../../Link";

const useStyles = makeStyles((theme) => styles(theme));

export default function ProductCardItem(props) {
  const classes = useStyles();

  return (
    <Link
      to={props.uri}
      className={`product-signup mb-0 ${classes.productCardItem}`}
    >
      {props.productGallery && (
        <img src={props.productGallery[0].image} alt="" />
      )}
      {props.productGallery && props.default && props.default.localFile && (
        <Img fluid={props.default.localFile.childImageSharp.fluid} />
      )}
      <span className="code">{props.code}</span>
      <span className="name">{props.title}</span>
      <span className="link">View Details</span>
    </Link>
  );
}
