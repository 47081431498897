
export default theme => ({
    productCardItem: {
        border: '1px solid rgba(15,15,15,0.3)',
        backgroundColor: 'white',
        // boxShadow: '0 8px 16px rgba(0,0,0,0.16)',
        padding: '44px 44px 86px',
        textDecoration: 'none',
        position: 'relative',
        display: 'block',
        height: '100%',
        marginBottom: 24,
        '& .gatsby-image-wrapper': {
            marginBottom: 32,
        },
        '& span.code': {
            display: 'block',
            fontFamily: 'Roboto Condensed',
            opacity: .3,
            letterSpacing: '0.152em',
            textTransform: 'uppercase',
            lineHeight: 1.2,
            fontSize: 12,
            marginBottom: 24,
            color: theme.common.black,
            textDecoration: 'none',
            [theme.breakpoints.up('md')]: {
                fontFamily: theme.headerFont,
                letterSpacing: '0.016em',
                opacity: 1,
                lineHeight: 1.5,
                fontWeight: 'bold',
                fontSize: 16,
            }
        },
        '& span.name': {
            fontFamily: theme.headerFont,
            color: theme.palette.primary.main,
            letterSpacing: '0.016em',
            lineHeight: 1,
            fontSize: 24,
            textDecoration: 'none',
            display: 'block',
            marginBottom: 24,
            fontWeight: 'bold',
        },
        '& span.link': {
            position: 'absolute',
            borderTop: '1px solid #EDEDED',
            bottom: 32,
            left: 44,
            right: 44,
            fontFamily: theme.headerFont,
            color: theme.palette.secondary.main,
            fontSize: 18,
            fontWeight: 'bold',
            letterSpacing: '0.016em',
            textAlign: 'center',
            lineHeight: 1.2,
            paddingTop: 32,
        }
    }
})